<template>
	<div class="box">
		<div class="flex" style="justify-content: space-between">
			<Title title="筛选条件" />
			<!-- <button class="derive">导出</button> -->
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapState } from 'vuex'
export default {
	components: {
		Title,
	},
	computed: {
		...mapState(['UserMediaNav', 'UserMedia']),
	},
	mounted() {
		this.$store.commit('childTab', '媒介资源')
	},
	data() {
		return {
			// navList: [],
			// 媒体资源
			TypeItem: '新闻媒体资源', //媒体资源选项卡
			// 门户类型
			PortalType: '不限',
			PortalList: [],
			// 频道类型
			ChannelType: '不限',
			ChannelList: [],
			//所属区域
			TerritoryType: '不限',
			TerritoryList: [],
			// 所属区域
			RecordingType: '不限',
			RecordingList: [],
			// 价格区间
			MoneyType: '不限',
			MoneyList: ['不限', '0-20', '21-50', '76-100', '100以上'],
			// 特殊类目
			SpecialType: '不限',
			SpecialList: [],
			// 排序
			SortType: '默认',
			SortList: [],
			MoneyState: 0, //价钱升序降序控制器
			CopmutedState: 0, //电脑权重升序降序控制器
			MoveState: 0, //移动权重升序降序控制器
			setMenuType: '全部套餐',
			totalMoney: 0, //价格总和
			/*-----------------媒体筛选------------------------*/
			mediaName: '', // 媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.derive {
	width: 6.67rem;
	height: 2.5rem;
	border: 0;
	background: #108cdd;
	color: #fff;
	border-radius: 0.42rem;
}
</style>